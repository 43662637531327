import { Description } from "@material-ui/icons";
import * as types from "./refreshResultActionTypes";
import _ from "lodash";
import { act } from "react";
const initialState = {
  refreshResultForm: [],
  refreshResultRef: [],
  refreshDayRequestForm: [],
  assignTotalTime: 0,
  useRefreshDate: new Date(),
  refreshLeaveType: "",
  description: "",
  refreshDayRequestAllList: [],
  refreshLeaveStatus: [],
  refreshCount: 0,
  originRefreshCount: 0,
};

const refreshResultReducer = (state = initialState, action) => {
  switch (action.type) {
    // REFRESH 결과보고서 팝업창에 신청서 추가 후 적용시
    case types.RR_UPDATE_REFRESH_REQUEST_FORM_DATA_STATE: {
      const assignTotalTime = action.refreshDayRequestForm.reduce(
        (acc, curr) => {
          return acc + (curr.assignTotalTime || 0);
        },
        0
      );

      //
      const originState = [...state?.refreshDayRequestForm];

      // 객체의 값들만 배열로 뽑아서 reduce 적용
      const originRefresh = Object.values(originState)?.reduce((acc, curr) => {
        return acc + (Number(curr.workHourType) === 26 ? 0.5 : 1);
      }, 0);

      //

      const refreshCount = action.refreshDayRequestForm.reduce((acc, curr) => {
        return acc + (Number(curr.workHourType) === 26 ? 0.5 : 1);
      }, 0);

      // console.log("originRefresh", originRefresh);
      // console.log("refreshCount", refreshCount);
      // console.log("action.refreshDayRequestForm", action.refreshDayRequestForm);
      //
      const updateRefreshLeaveStatus = {
        ...state.refreshLeaveStatus, // 기존의 상태를 복사
        assignDays:
          state.refreshLeaveStatus === null
            ? 0
            : state.refreshLeaveStatus.assignDays -
              refreshCount +
              // state.originRefreshCount, // assignDays 업데이트
              originRefresh, // assignDays 업데이트
        remainingDays:
          state.refreshLeaveStatus === null
            ? 0
            : state.refreshLeaveStatus.remainingDays -
              refreshCount +
              // state.originRefreshCount, // 필요한 경우 remainingDays 업데이트
              originRefresh, // 필요한 경우 remainingDays 업데이트
        totalUseDays:
          state.refreshLeaveStatus === null
            ? 0
            : state.refreshLeaveStatus.totalUseDays +
              refreshCount -
              // state.originRefreshCount, // 필요한 경우 totalUseDays 업데이트
              originRefresh, // 필요한 경우 totalUseDays 업데이트
      };
      //연차 수정
      const checkRefreshLeaveCount = action?.refreshDayRequestForm?.reduce(
        (acc, curr) => {
          return acc + (Number(curr.workHourType) === 26 ? 0.5 : 1);
        },
        0
      );
      // console.log("checkRefreshLeaveCount", checkRefreshLeaveCount);

      return {
        ...state,
        refreshDayRequestForm: action.refreshDayRequestForm,
        refreshLeaveStatus: updateRefreshLeaveStatus,
        assignTotalTime,
        refreshLeaveType: parseFloat(checkRefreshLeaveCount) === 1 ? "AL" : "",
        // refreshLeaveType: assignTotalTime >= 480 ? "AL" : "",
        refreshCount,
        //
        originRefreshCount: originRefresh, // originRefresh 값을 originRefreshCount에 저장
      };
    }
    //REFRESH 결과보고서 다시 접근할때 Reset
    case types.RR_RESET_REFRESH_REQUEST_FORM_DATA: {
      return {
        ...state,
        refreshResultForm: [],
        refreshResultRef: [],
        refreshDayRequestForm: [],
        assignTotalTime: 0,
        useRefreshDate: new Date(),
        refreshLeaveType: "",
        description: "",
        refreshDayRequestAllList: [],
        refreshLeaveStatus: [],
        refreshCount: 0,
        originRefreshCount: 0,
      };
    }
    //REFRESH 결과보고서 실 근무시간 수정시
    case types.RR_UPDATE_REFRESH_DAY_RESULT_TIME_MMHH: {
      console.log(
        "RR_UPDATE_REFRESH_DAY_RESULT_TIME_MMHH",
        action.refreshDayRequestForm
      );
      return {
        ...state,
        refreshDayRequestForm: action.refreshDayRequestForm,
      };
    }
    //REFRSH 결과보고서 RefreshDay 사용 예정일 수정시
    case types.RR_UPDATE_REFRESH_DAY_RESULT_USE_REFRESH_DATE: {
      return {
        ...state,
        useRefreshDate: action.payload,
      };
    }
    //REFRSH 결과보고서 RefreshDay 종일/오전/오후 라디오 버튼 선택시
    case types.RR_UPDATE_REFRESH_DAY_RESULT_REFRESH_LEAVE_TYPE: {
      return {
        ...state,
        refreshLeaveType: action.payload,
      };
    }
    //REFRSH 결과보고서 RefreshDay 비고 수정시
    case types.RR_UPDATE_REFRESH_DAY_RESULT_NOTE: {
      return {
        ...state,
        description: action.payload,
      };
    }

    //REFRSH 결과보고서 > RefreshDay 신청서 선택 > 팝업창에서 조회시 전체 신청서 리스트 가져오기
    case types.RR_FETCH_REFRESH_REQUEST_FORM_LIST_ALL: {
      return {
        ...state,
        refreshDayRequestAllList: action.payload,
      };
    }
    //

    //REFESH 결과보고서 최초 로딩시 > 해당 유저 Refresh 연차 가지고 오기
    case types.RR_FETCH_EMPLOYEE_REFRESH_YEAR_STATUS: {
      return {
        ...state,
        refreshLeaveStatus: action.payload,
      };
    }
    //

    //REFRESH 결과보고서 조회시 데이터 바인딩
    case types.RR_FETCH_REFRESH_RESULT_FORM_DATA: {
      const { refreshResultForm, refreshResultRef, refreshDayRequestForm } =
        action.payload;
      console.log("action.payload", action.payload);
      //기존연차 redux에 넣기
      const originRefreshCount = refreshDayRequestForm?.reduce((acc, curr) => {
        return acc + (Number(curr.workHourType) === 26 ? 0.5 : 1);
      }, 0);

      //
      const updateAssignTime = refreshResultRef?.reduce((acc, curr) => {
        return acc + curr.workTotalTime;
      }, 0);

      const mergeData = refreshDayRequestForm.map((item) => {
        // filter를 사용해 조건에 맞는 항목 찾기
        const matchedItem = refreshResultRef.find(
          (ele) =>
            Number(item.userFormSeq) === Number(ele.refreshDayRequestFormSeq)
        );
        console.log("matchedItem", matchedItem);
        // 매칭된 항목이 있으면 데이터를 병합하여 반환
        if (matchedItem) {
          return {
            ...item,
            workStartTime: matchedItem.workStartTime,
            workEndTime: matchedItem.workEndTime,
            workTotalTime: matchedItem.workTotalTime,
            //
            srtDateHH: matchedItem.workStartTime?.substring(0, 2),
            srtDateMM: matchedItem.workStartTime?.substring(3, 5),
            endDateHH: matchedItem.workEndTime?.substring(0, 2),
            endDateMM: matchedItem.workEndTime?.substring(3, 5),
            //
          };
        }

        // 매칭된 항목이 없다면 원본 item 반환
        return item;
      });
      console.log("mergeData", mergeData);
      const formattedDateString = `${refreshResultForm?.useRefreshDate.substring(
        0,
        4
      )}-${refreshResultForm?.useRefreshDate.substring(
        4,
        6
      )}-${refreshResultForm?.useRefreshDate.substring(6, 8)}`;

      // 상태 업데이트
      return {
        ...state,
        refreshResultForm: refreshResultForm || [], // 값이 없으면 빈 배열
        refreshResultRef: refreshResultRef || [], // 값이 없으면 빈 배열
        refreshDayRequestForm: mergeData || [], // 값이 없으면 빈 배열
        refreshLeaveType: refreshResultForm?.refreshLeaveType,
        description: refreshResultForm?.description,
        assignTotalTime: updateAssignTime || 0,
        // useRefreshDate: formattedDateString,
        useRefreshDate: new Date(formattedDateString),
        originRefreshCount: originRefreshCount,
      };
    }
    default:
      return state || initialState; // 기존 상태 반환
  }
};

export default refreshResultReducer;
