export const RR_UPDATE_REFRESH_REQUEST_FORM_DATA_STATE =
  "RR_UPDATE_REFRESH_REQUEST_FORM_DATA_STATE";
export const RR_RESET_REFRESH_REQUEST_FORM_DATA =
  "RR_RESET_REFRESH_REQUEST_FORM_DATA";
export const RR_UPDATE_REFRESH_DAY_RESULT_TIME_MMHH =
  "RR_UPDATE_REFRESH_DAY_RESULT_TIME_MMHH";
export const RR_UPDATE_REFRESH_DAY_RESULT_USE_REFRESH_DATE =
  "RR_UPDATE_REFRESH_DAY_RESULT_USE_REFRESH_DATE ";
export const RR_UPDATE_REFRESH_DAY_RESULT_REFRESH_LEAVE_TYPE =
  "RR_UPDATE_REFRESH_DAY_RESULT_REFRESH_LEAVE_TYPE";
export const RR_UPDATE_REFRESH_DAY_RESULT_NOTE =
  "RR_UPDATE_REFRESH_DAY_RESULT_NOTE";
export const RR_FETCH_REFRESH_REQUEST_FORM_LIST_ALL =
  "RR_FETCH_REFRESH_REQUEST_FORM_LIST_ALL";
export const RR_FETCH_EMPLOYEE_REFRESH_YEAR_STATUS =
  "RR_FETCH_EMPLOYEE_REFRESH_YEAR_STATUS";
export const RR_FETCH_REFRESH_RESULT_FORM_DATA =
  "RR_FETCH_REFRESH_RESULT_FORM_DATA";
