import * as types from "./personalAnnualConfirmActionTypes";
import { formatStringDate } from "../../../util/date";
import { PAGE_SIZE, PAGE_SIZES } from "./../../../util/config";
import { Translation } from "react-i18next";
import i18next from "i18next";

const initialSate = {
  lstYearCodes: [],
  yearSelected: new Date().getFullYear(),
  lstAnnualConfirm: [],
  searchCondition: {
    page: 0,
    pageSize: PAGE_SIZE,
  },
  lstAnnualLeaveOfCurrentYear: [],
  totalRow: 0,
  pageSizes: PAGE_SIZES,
};
const personalAnnualConfirmReducer = (state = initialSate, action) => {
  switch (action.type) {
    case types.PAC_FETCH_EMPLOYEE_YEAR_CODES: {
      state.lstYearCodes = action.payload.lstYearCodes;
      return { ...state };
    }
    case types.PAC_ON_CHANGE_YEAR_CODE_SELECTED: {
      state.yearSelected = action.payload.yearSelected;
      return { ...state };
    }
    case types.PAC_FETCH_EMPLOYEE_ANNUAL_LEAVE_YEAR: {
      console.log(
        "PAC_FETCH_EMPLOYEE_ANNUAL_LEAVE_YEAR-action.payload",
        action.payload
      );
      let lstAnnualConfirm =
        action.payload.lstAnnualConfirm.annualLeaveYearResult;
      lstAnnualConfirm.forEach(
        (element) =>
          (element.remainingDays =
            element.remainingDays < 0 ? 0 : element.remainingDays)
      );
      state.lstAnnualConfirm = lstAnnualConfirm;
      return { ...state };
    }
    case types.PAC_FETCH_ANNUAL_LEAVE_OF_CURRENT_YEAR: {
      let lstAnnualLeaveOfCurrentYear =
        action.payload.lstAnnualLeaveOfCurrentYear;
      if (lstAnnualLeaveOfCurrentYear.length !== 0) {
        lstAnnualLeaveOfCurrentYear.forEach(
          (element) =>
            (element.dateYmd = formatStringDate(element.dateYmd, "-", true))
        );
        state.totalRow = lstAnnualLeaveOfCurrentYear[0].totalRow;
      } else {
        state.totalRow = 0;
      }
      state.lstAnnualLeaveOfCurrentYear = lstAnnualLeaveOfCurrentYear;
      return { ...state };
    }
    case types.PAC_ON_CHANGE_SEARCH_CONDITION: {
      state.searchCondition = action.payload.searchCondition;
      return { ...state };
    }
    default:
      return { ...state };
  }
};
export default personalAnnualConfirmReducer;
